import React from 'react';
import {useState} from 'react';
import {Navigate, Outlet, Routes, Route, useLocation, useNavigate} from "react-router-dom";

import Home from './components/pages/Home/Home.js';
import KrogerPOC from './components/pages/KrogerPOC/KrogerPOC.js';
import ExecutiveWalk from './components/pages/ExecWalk/ExecutiveWalk.js';

function App() {

    //Constants
    const appSignature = "ConnectedStore";
    const appSettingsModel = {
        "authenticated": false,
        "api":"dev",
        "data": {},
        "theme": {
            mode: 'light'
        },
        "routing": {
            "next": 'Connection-Points'
        },
        "user": {
            "fname": "",
            "lname": "",
            "email": "",
            "title": "",
            "image": "",
            "bearer": "",
            "csrf": "",
        }
    }
    const location = useLocation();
    const pathName = location.pathname;

    //Variables
    let appSettings = {};

   
    //Getters & Setters
    //const [colorMode, setColorMode] = useState();

    //Functions
    const PrivateRoutes = () => {
        //let toValue = '/login/?rd=' + encodeURIComponent(pathName);

        appSettings.routing.next = pathName;
        localStorage.setItem(appSignature, JSON.stringify(appSettings))

        return (
            appSettings.authenticated ? <Outlet/> : <Navigate to='/'/>
        )
    }

    //Local storage will hold app settings.  For prototype we'll check local storage for authenticated boolean
    if (!localStorage.getItem(appSignature)) {
        localStorage.setItem(appSignature, JSON.stringify(appSettingsModel));
    } else {
        
        appSettings = JSON.parse(localStorage.getItem(appSignature));

        if (appSettings.theme.mode === 'light') {
            document.documentElement.classList.remove('dark');
            //window.matchMedia('(prefers-color-scheme: dark)').matches)
        } else {
            document.documentElement.classList.add('dark');
        }
    }

    return (
        <Routes>
            <Route exact path="/*" element={<Home />} />
            <Route path="/krogerpoc" element={<KrogerPOC />} />
            <Route path='/execwalk' element={<ExecutiveWalk />} />
        </Routes>
    );
}

export default App;
